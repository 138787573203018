import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import Layout from '../components/Layout';
import Container from '../components/Container';
import ContentfulRichText from '../components/contentfulRichText';
import { ContentfulProject } from '../../types/graphql-types';
import NextItemLink from '../components/NextItemLink';

interface Props {
  data: {
    site: {
      siteMetadata: {
        id?: string;
        title?: string;
      };
    };
    contentfulProject: Partial<ContentfulProject>;
  };
  siteTitle?: string;
  pageContext?: {
    slug: string;
    next?: Partial<ContentfulProject>;
  };
}

const ProjectTemplate: React.FC<Props> = ({ data, pageContext }: Props) => {
  return (
    <Layout>
      <Container variant="wide">
        <Helmet
          title={`${data.contentfulProject.title} | ${data.site.siteMetadata.title}`}
        />
        <div style={{ marginTop: '6rem' }} />
        <Container variant="small" noPad>
          <Typography variant="h2" component="h1" color="primary">
            {data.contentfulProject.title}
          </Typography>
          <Typography variant="h3" component="h2" color="textSecondary">
            {data.contentfulProject.subtitle}
          </Typography>
        </Container>
        <div style={{ marginTop: '2rem' }} />
        {data.contentfulProject.featuredImage?.fluid ? (
          <>
            <Img
              alt={data.contentfulProject.title || ''}
              fluid={data.contentfulProject.featuredImage.fluid}
            />
            <div style={{ marginTop: '2rem' }} />
          </>
        ) : null}
        <Container variant="small" noPad>
          <Typography variant="body1">
            {data.contentfulProject.updatedAt}
          </Typography>
          <ContentfulRichText document={data.contentfulProject?.body?.json} />
          {pageContext?.next?.title ? (
            <NextItemLink
              path={`/portfolio/${pageContext?.next.slug}`}
              title={pageContext?.next.title}
              subtitle={pageContext?.next.subtitle || undefined}
            />
          ) : null}
        </Container>
      </Container>
    </Layout>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      title
      subtitle
      featuredImage {
        fluid(maxWidth: 1180) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        json
      }
    }
  }
`;
