import * as React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'gatsby';
import muiTheme from '../config/theme';

interface Props {
  path: string;
  title: string;
  subtitle?: string;
}

const ArticleLinkContainer = styled(Link)`
  display: block;
  background: ${muiTheme.palette.primary.light};
  padding: 2rem 3rem;
  color: white;
  text-decoration: none;

  &:hover {
    color: white;
    background: ${muiTheme.palette.primary.main};
  }
`;

const NextItemLink: React.FC<Props> = ({ path, title, subtitle }: Props) => {
  return (
    <div>
      <ArticleLinkContainer to={path}>
        <Typography variant="overline" color="inherit" style={{ opacity: 0.8 }}>
          Next Post
        </Typography>
        <Typography variant="h4" color="inherit">
          {title}
        </Typography>
        {subtitle ? (
          <Typography variant="h6" color="inherit" style={{ opacity: 0.9 }}>
            {subtitle}
          </Typography>
        ) : null}
      </ArticleLinkContainer>
    </div>
  );
};

export default NextItemLink;
